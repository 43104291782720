jQuery(function($) {'use strict',
    //Initiat WOW JS
    new WOW().init();
    
    //slider
    $(window).load(function(){
        $('.flexslider').flexslider({
            slideshow: true,
            pauseOnHover: true,
            controlNav: false,
            initDelay: 0,
            animation: "slide",
            slideshowSpeed: 7000,
            start: function(slider){
                $('body').removeClass('loading');
            }
        });
    });

    //Smooth Page Scrolling
    $(function() {
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });
    });
    
    
    
    // Create start date
    var start = new Date(),
        prevDay,
        startHours = 9;
    // 09:00 AM
    start.setHours(9);
    start.setMinutes(0);
    // If today is Saturday or Sunday set 10:00 AM
    if ([6, 0].indexOf(start.getDay()) != -1) {
        start.setHours(10);
        startHours = 10
    }
    $('.datepicker-here').datepicker({
        timepicker: true,
        language: 'en',
        minDate: new Date(),
        startDate: start,
        minHours: startHours,
        maxHours: 18,
        onSelect: function (fd, d, picker) {
            // Do nothing if selection was cleared
            if (!d) return;

            var day = d.getDay();

            // Trigger only if date is changed
            if (prevDay != undefined && prevDay == day) return;
            prevDay = day;

            // If chosen day is Saturday or Sunday when set
            // hour value for weekends, else restore defaults
            if (day == 6 || day == 0) {
                picker.update({
                    minHours: 10,
                    maxHours: 16
                })
            } else {
                picker.update({
                    minHours: 9,
                    maxHours: 18
                })
            }
        }
    })
});


jQuery(function($) {
    
    $.validator.addMethod("customemail", 
        function(value, element) {
//            return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value);
            return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.(com|co\.(in|uk|rs)|org\.(rs)|edu\.(rs)|me|org|net|edu|gov|info|срб)$/.test(value);
        }, 
        "Email is not in valid format"
    );
    
    // KONTAKT MODAL
    $("#kontakt_forma").validate({
        rules :{
            name: {
                required: true
            },
            email: {
                required: true,
                email: true,
                customemail: true
            },
            phone: {
                required: true
            },
            adress: {
                required: true
            },
            poruka: {
                required: true
            }
        },
       messages :{
            name: "You must enter your full name",
            email: {
               required: "You must enter your email address",
               email: "Email is not in valid format"
            },
            phone: "You must enter your phone number",
            adress: "You must enter your address",
            poruka: "You must enter message"
        }
    });
    
    
    // ZAKAZI MODAL
    $("#zakazite_modal_form").validate({
        rules :{
            name: {
                required: true
            },
            date: {
                required: true
            },
            number: {
                required: true
            },
            phone: {
                required: true
            }
        },
       messages :{
            name: "You must enter your full name",
            date: "You must enter date of visit",
            number: "Enter number of planned visitors",
            phone: "You must enter your phone number"
        }
    });
});